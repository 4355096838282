<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-white bg-white shadow-sm">
       <div class="navbar-nav mr-auto">
        <router-link to="/" class="navbar-brand">
          <img src="@/assets/logo.png" alt="PRAGYA-AI" class="logo" />
        </router-link>
        <li class="nav-item">
          <router-link to="/getallcoursemc" class="nav-link">Competencies</router-link>
        </li>
         <!--
        <li class="nav-item">
          <router-link to="/kcexplanation" class="nav-link">KC Explanation</router-link>
        </li>
        -->
        <li class="nav-item">
          <router-link to="/getcourselist" class="nav-link">KC</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/getcourselistforprompts" class="nav-link">Prompts</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/getprecannedquestions" class="nav-link">Questions</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/getprecannedrails" class="nav-link">Guardrails</router-link>
        </li>
        
        <!--
          <li class="nav-item">
            <router-link to="/sendeEmail" class="nav-link">Mail</router-link>
          </li> 
        -->
        <li class="nav-item">
          <router-link to="/aide" class="nav-link">Author-Aide</router-link>
        </li> 
      </div>
      <div class="logo-container">
        <router-link to="/">
          <img v-if="institutionLogo" :src="institutionLogo" alt="Institution Logo" class="logo" />
        </router-link>
      </div>
      <div class="navbar-nav ml-auto">
        <li class="nav-item">
          <router-link v-if="isLoggedIn" to="/logout" class="nav-link">Logout</router-link>
          <router-link v-else to="/login" class="nav-link">Login</router-link>
        </li>
      </div>
    </nav>
    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem('token');
    },
    institutionLogo() {
      return this.$store.state.institutionLogo;
    }
  },
  created() {
    // Check token on page load
    if (!this.isLoggedIn) {
      // Redirect to login page if token is not present
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    reloadApp() {
      // Reload the entire application to trigger re-evaluation of computed properties or methods
      window.location.reload();
    }
  },
  watch: {
    $route(to, from)  {
      // Reload App.vue component when route changes
      //console.log('Route changed');
      //console.log('to: ', to);
      //console.log('from: ', from);  
      //also check if to and from are null
      if (to.name === undefined || from.name === undefined) {
        return;
      } 
      if (to.name !== from.name) {
        this.reloadApp();
      }
    }
  }
};
</script>

<style>
  body {
    font-family: 'Arial', sans-serif; /* Use your preferred modern font here */
    padding-left: 10px; /* Add padding to the left side */
    padding-right: 10px; /* Add padding to the right side */
  }

  .navbar-nav.mr-auto {
    flex-grow: 1;
    padding-left: 10px; /* Add left padding */
    padding-right: 10px; /* Add right padding */
  }

  .navbar-nav.ml-auto {
    justify-content: flex-end;
    padding-left: 10px; /* Add left padding */
    padding-right: 10px; /* Add right padding */
  }

  .logo {
    max-height: 40px; /* Adjust the max height as needed */
    width: auto; /* Let the width adjust based on height to maintain aspect ratio */
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto; /* Remove the fixed margin */
    margin-left: auto; /* Add margin-left:auto to center the logo */
  }
</style>